import React from 'react';
import styled from 'styled-components';

import PageBase from '../components/PageBase';

export default function PrivacyStatementAndFSG() {
	return (
		<PageBase title="Error 404">
			<StyledErrorPage>
				<h3>Error 404</h3>
				<p>Page not found.</p>
			</StyledErrorPage>
		</PageBase>
	);
}

const StyledErrorPage = styled.div`
	width: 100%;
	padding: 50px 0;
	text-align: center;

	h3 {
		color: #192851;
		font-size: 32px;
		font-weight: 700;
		line-height: 71px;
	}

	p {
		color: #49667b;
		font-size: 14px;
		font-weight: 400;
		text-align: center;
		line-height: 1.79;
		margin-bottom: 20px;
	}
`;
